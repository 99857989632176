@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";


.App {
  /* text-align: center; */
}
body{
  overflow-x: hidden;
  background-color: #ffff;
}

.small-img{
  display: none;
}

@media (max-width: 768px) {
  .culture {
    display: none;
  }
  .map {
    display: none;
  }
  .big-img{
    display: none;
  }
  .small-img{
    display: block;
  }
}